import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // 라우터 임포트
import './assets/styles.css';
import './assets/animations.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/tailwind.css';
import 'animate.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '@fortawesome/fontawesome-free/css/all.min.css';

AOS.init({
    duration: 800, // 애니메이션 지속 시간 (밀리초) 조정
    once: false, // 애니메이션이 한 번만 실행되지 않고 다시 나타날 때마다 실행되도록 설정
    offset: 100, // 스크롤 기준으로 애니메이션이 시작되는 위치 (픽셀 단위)
    easing: 'ease-in-out', // 애니메이션의 속도 조정 (기본값: 'ease')
    anchorPlacement: 'top-bottom' // 애니메이션이 시작되는 위치
});

createApp(App).use(router).mount('#app');

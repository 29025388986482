<template>
  <div id="contact">
    <div class="contact-section">
      <h2 class="section-title">Contact</h2>
      <div class="contact-cards">
        <!-- Email Contact -->
        <div class="contact-card" data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-in-out">
          <i class="fas fa-envelope contact-icon"></i>
          <h3>Email</h3>
          <p>Feel free to reach me via email.</p>
          <a href="mailto:martinhajuna@gmail.com" class="contact-link">martinhajuna@gmail.com</a>
        </div>

        <!-- LinkedIn Contact -->
        <div class="contact-card" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500" data-aos-easing="ease-in-out">
          <i class="fab fa-linkedin contact-icon"></i>
          <h3>LinkedIn</h3>
          <p>Connect with me on LinkedIn.</p>
          <a href="https://www.linkedin.com/in/hajun-shin-19a885303" target="_blank" class="contact-link">Hajun Shin</a>
        </div>

        <!-- GitHub Contact -->
        <div class="contact-card" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500" data-aos-easing="ease-in-out">
          <i class="fab fa-github contact-icon"></i>
          <h3>GitHub</h3>
          <p>Check out my projects and code.</p>
          <a href="https://github.com/shinhajun" target="_blank" class="contact-link">github.com/shinhajun</a>
        </div>

        <!-- Instagram Contact -->
        <div class="contact-card" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500" data-aos-easing="ease-in-out">
          <i class="fab fa-instagram contact-icon"></i>
          <h3>Instagram</h3>
          <p>Follow me on Instagram.</p>
          <a href="https://www.instagram.com/_hajun1103/" target="_blank" class="contact-link">@_hajun1103</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
};
</script>

<style scoped>
.contact-section {
  padding: 4rem 0;
  background-color: #f0f2f5;
  text-align: center;
}

.section-title {
  font-size: 3rem;
  margin-bottom: 3rem;
  color: #333;
  font-weight: bold;
  position: relative;
}

.section-title::after {
  content: "";
  width: 60px;
  height: 4px;
  background-color: #ff4b2b;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.contact-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.contact-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 280px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0; /* 초기 상태: 투명하게 숨김 */
  transform: translateY(50px); /* 초기 상태: 아래로 이동된 위치 */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out; /* 애니메이션 전환 설정 */
}

.contact-card.aos-init.aos-animate {
  opacity: 1; /* 애니메이션 중: 보이게 설정 */
  transform: translateY(0); /* 애니메이션 중: 원래 위치로 이동 */
}

.contact-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #ff4b2b;
}

.contact-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.contact-card p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #666;
}

.contact-link {
  display: inline-block;
  background-color: #ff4b2b;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.contact-link:hover {
  background-color: #e04327;
}
</style>
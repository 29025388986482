<template>
  <section id="timeline" class="timeline section">
    <div class="container">
      <h2 class="section-title text-center">Timeline</h2>
      <div class="timeline-container">
        <!-- 타임라인 항목 -->
        <div
          v-for="(item, index) in timeline"
          :key="index"
          class="timeline-item-wrapper"
          :class="{ 'is-visible': startAnimation }"
          :style="{ transitionDelay: `${index * 0.3}s` }"
        >
          <div class="timeline-item">
            <div class="timeline-content">
              <h3 class="timeline-year">{{ item.year }}</h3>
              <p class="timeline-description">{{ item.description }}</p>
            </div>
          </div>
          <!-- 화살표 추가, 마지막 항목에는 화살표 표시 안함 -->
          <div v-if="index < timeline.length - 1" class="arrow-wrapper">
            <div class="arrow">
              <i class="fas fa-arrow-down"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TimeLine',
  data() {
    return {
      timeline: [
        { year: '2015 - 2024', description: 'Studied game development and programming.' },
        { year: '2024 - 2028', description: 'Stony Brook University(BS-Electrical Engineering)' },
        { year: '2028 - 2031', description: 'University(MS-Electrical Engineering)' },
        { year: '2031 - 20', description: '-' },
      ],
      startAnimation: false, // 애니메이션 시작 여부
    };
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  methods: {
    setupIntersectionObserver() {
      const options = {
        threshold: 0.1, // 요소가 10% 보일 때 콜백 실행
      };
      const observer = new IntersectionObserver(this.handleIntersect, options);
      const timelineSection = document.querySelector('#timeline');
      observer.observe(timelineSection);
    },
    handleIntersect(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.startAnimation = true; // 애니메이션 시작
        }
      });
    },
  },
};
</script>

<style scoped>
.timeline {
  background-color: #f8f9fa;
  padding: 60px 0;
  position: relative;
}

.timeline-container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  opacity: 0; /* 초기 상태는 숨김 */
  transform: translateY(20px); /* 살짝 아래로 이동된 상태 */
  transition: opacity 0.8s ease-out, transform 0.8s ease-out; /* 애니메이션 효과 */
}

.timeline-item-wrapper.is-visible {
  opacity: 1; /* 보이게 설정 */
  transform: translateY(0); /* 원래 위치로 이동 */
}

.timeline-item {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px 0; /* 타임라인 항목 사이 간격 */
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 2; /* 내용이 선 위에 있도록 z-index 설정 */
}

.timeline-content {
  position: relative;
}

.timeline-year {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.timeline-description {
  margin: 0;
  font-size: 1.1rem;
}

.arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; /* 화살표를 위한 여백 */
}

.arrow {
  font-size: 2.5rem; /* 화살표 크기 */
  color: #ff4b2b; /* 화살표 색상 */
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .timeline-item {
    width: 100%;
  }
}
</style>

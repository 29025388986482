<template>
    <div>
      <Header /> <!-- 헤더 컴포넌트 추가 -->
  
      <div class="electrical-engineering-projects">
        <h1>Electrical Engineering Projects</h1>
        <p>Explore projects related to Electrical Engineering, including circuit design, signal processing, and applications of modern technology in engineering.</p>
        <!-- 프로젝트 목록을 여기에 추가할 수 있습니다 -->
      </div>
    </div>
  </template>
  
  <script>
  import Header from './Header.vue'; // 헤더 컴포넌트 임포트
  
  export default {
    name: 'Electrical-Engineering',
    components: {
      Header, // 헤더 컴포넌트를 사용
    },
  };
  </script>
  
  <style scoped>
  .electrical-engineering-projects {
    padding: 2rem;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  p {
    font-size: 1.2rem;
  }
  </style>
  
<template>
  <div id="app">

    <router-view />

  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* 외부 스타일 불러오기 */
@import './assets/styles.css';
@import './assets/animations.css';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'animate.css/animate.min.css';
@import '@fortawesome/fontawesome-free/css/all.min.css';

/* #app 스타일 */
#app {
  width: 100%;
  overflow-x: hidden; /* 좌우 스크롤바를 숨김 */
}

/* 페이지에서 기본적인 여백을 없앰 */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

</style>

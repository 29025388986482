<template>
    <div>
      <Header /> <!-- 헤더 컴포넌트 추가 -->
  
      <div class="development">
        <h1>Development</h1>
        <p>Here you can find a collection of development projects.</p>
        <!-- 프로젝트 목록을 여기에 추가할 수 있습니다 -->
      </div>
    </div>
  </template>
  
  <script>
  import Header from './Header.vue'; // 헤더 컴포넌트 임포트
  
  export default {
    name: 'Development',
    components: {
      Header, // 헤더 컴포넌트를 사용
    },
  };
  </script>
  
  <style scoped>
  .development {
    padding: 2rem;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  p {
    font-size: 1.2rem;
  }
  </style>
  
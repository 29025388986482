<template>
    <div id="home">
      <Header /> <!-- 헤더 컴포넌트 불러오기 -->
      <main id="main">
        <MainSection /> <!-- About 섹션 -->
      </main>
      <section id="timeline">
        <TimeLine /> <!-- Timeline 섹션 -->
      </section>
      <section id="projects">
        <Projects /> <!-- Projects 섹션 -->
      </section>
      <section id="contact">
        <Contact /> <!-- Contact 섹션 -->
      </section>
    </div>
  </template>
  
  <script>
  import Header from '@/components/Header.vue';
  import MainSection from '@/components/MainSection.vue';
  import TimeLine from '@/components/TimeLine.vue';
  import Projects from '@/components/Projects.vue';
  import Contact from '@/components/Contact.vue';
  
  export default {
    name: 'Home',
    components: {
      Header,
      MainSection,
      TimeLine,
      Projects,
      Contact
    }
  };
  </script>
  
  <style scoped>
  #home {
    width: 100%;
  }
  </style>
  
<template>
  <header class="header">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container">
        <router-link class="navbar-brand" to="/">Portfolio</router-link>
        
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="/#main">About</router-link> <!-- Home 내 About 섹션으로 이동 -->
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/#timeline">Timeline</router-link> <!-- Home 내 Timeline 섹션으로 이동 -->
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/#projects">Projects</router-link> <!-- Home 내 Projects 섹션으로 이동 -->
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/#contact">Contact</router-link> <!-- Home 내 Contact 섹션으로 이동 -->
            </li>
          </ul>
          <ul class="navbar-nav ms-auto social-icons">
            <li class="nav-item">
              <a class="nav-link" href="https://www.linkedin.com/in/hajun-shin-19a885303" target="_blank">
                <i class="fab fa-linkedin"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://github.com/shinhajun" target="_blank">
                <i class="fab fa-github"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.instagram.com/_hajun1103" target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header'
};
</script>

<style scoped>
/* 네비게이션 스타일 */
.navbar {
  background-color: #333;
}
.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold; /* 포트폴리오 제목 강조 */
}
.nav-link {
  color: #fff !important;
}
.social-icons .nav-link {
  font-size: 1.5rem;
}
.social-icons i {
  color: #fff;
  transition: color 0.3s ease;
}
.social-icons i:hover {
  color: #ff4b2b; /* 호버 시 색상 변경 */
}
</style>
